import "./App.css";

function App() {
  return (
    <div className="main">
      <header>
        <h1>Samuel DeZube</h1>
      </header>
      <main>
        <section>
          <h2>About Me</h2>
          <p>
            My name is Samuel DeZube and I am a senior Finance major with minors
            in Computer Science and Math. I am a singer/songwriter and play
            guitar and sing in a local band our of Lincoln, Nebraska called
            Loose Change. I have worked as a Software Engineer for Garmin and
            have done contract work but have since pivoted to creative roles
            like product management and corporate innovation.
          </p>
        </section>
        <section>
          <h2>Work Experience</h2>
          <ul>
            <li>Software Engineer, Garmin (2018-2019)</li>
            <li>Product Manager, XYZ Corporation (2020-present)</li>
            <li>Corporate Innovation Consultant, ABC Company (2021-present)</li>
          </ul>
        </section>
        <section>
          <h2>Education</h2>
          <ul>
            <li>B.S. in Finance, University of Nebraska-Lincoln (2018-2022)</li>
            <li>
              Minor in Computer Science, University of Nebraska-Lincoln
              (2018-2022)
            </li>
            <li>Minor in Math, University of Nebraska-Lincoln (2018-2022)</li>
          </ul>
        </section>
        <section>
          <h2>Skills</h2>
          <ul>
            <li>Product Management</li>
            <li>Corporate Innovation</li>
            <li>Software Engineering</li>
            <li>Singer/Songwriter</li>
            <li>Guitar</li>
          </ul>
        </section>
      </main>
      <footer>
        <p>Copyright 2021, Samuel DeZube</p>
      </footer>
    </div>
  );
}

export default App;
